import Patrick from './patrick';
import Sang from './sang';
import Arthur from './arthur';
import Kevin from './kevin';

export default function Body() {
	return (
		<div className="body">
			<h1>About Us</h1>
			<div className="body-mission">
				<h2>Our Mission</h2>
				<p>HAHAHAHAHHA MONEY AHAHAHAHAH</p>
			</div>
			<div className="body-bio">
				<h2>Founders</h2>
				<div className="body-founders">
					<div className="body-person">
						<Patrick />
					</div>
					<div className="body-person">
						<Sang />
					</div>
					<div className="body-person">
						<Arthur />
					</div>
					<div className="body-person">
						<Kevin />
					</div>
				</div>
			</div>
		</div>
	);
}