
function Hero(){
	return (
		<div className="hero">
			<div className="hero-title">
				<h1><a href="/">Onthego</a></h1>
			</div>
			<div className="hero-base">
				<h2>A New Delivery Paradigm</h2>
				<p>Providing a much cheaper delivery alternative by ordering from other customers already going to the same restaurant</p>
				<div className="hero-signup">
					<a href="https://forms.gle/FimVyZUjJJPDCMBs7" target="_blank">Sign Up</a>
				</div>
			</div>
		</div>
	);
}

export default Hero;