import './App.css';

import AppV0 from './v0/AppV0';

function App() {
  return (
    <div className="App">
      <AppV0 />
    </div>
  );
}

export default App;
