
function Motto(){
	return (
		<div className="motto">
			<div className="motto-item1">
				<h3>Everyone's a buyer</h3>
				<p>Drivers aren't workers, they're buyers with benefits</p>
				<p>By eliminating the "worker", the entire system is much more efficient</p>
			</div>
			<div className="motto-item2">
				<h3>Savings all around</h3>
				<div className="motto-item2-container">
					<div className="motto-item2-item1">
						<p>As a driver, save significantly on your next meal for every order you fulfill. Build up to a free meal in 2-3 orders</p>
					</div>
					<div className="motto-item2-item2">
						<p>As a buyer, only pay $2-3 for delivery, service, and tips combined, instead of $8-12 on traditional delivery platforms</p>
					</div>
				</div>
			</div>
			<div className="motto-item1">
				<h3>No tricks up our sleeve</h3>
				<p>No restaurant commission</p>
				<p>No order minimums</p>
				<p>No hidden fees</p>
			</div>
		</div>
	);
}

export default Motto;