import "./About.css";

import Header from "../../common/header";
import Body from "./body";
import Footer from '../../common/footer';

export default function About() {
	return(
		<div className="about">
			<Header />
			<Body />
			<Footer />
		</div>
	);
}