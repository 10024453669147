import './Main.css';          // ---> V2

import Hero from './hero';
import Motto from './motto';
import Footer from '../common/footer';

function Main(){

	return (
		<div className="Main">
			<Hero />
			<Motto />
			<Footer />
		</div>
	);

}


export default Main;