import { faqData } from "./faq_data";

export default function Body() {
	return (
		<div className="faq-body">
			<h2>FAQ</h2>
			<div className="faq-sections">
					{faqData.map((data,key) => {
						return (
							<div className="faq-section" key={key}>
								<h3>{data.question}</h3>
								<p>{data.answer}</p>
							</div>
						);
					})}
			</div>
		</div>
	);
}