import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Main from './pilot/Main/Main';
import PilotFAQ from './pilot/faq/FAQ';
import PilotAbout from './pilot/about_us/About';

import Homepage from './homepage/homepage';

import FAQ from './info/faq/FAQ';
import About from './info/about_us/About';

//import Constants from '.cookie/auth/Constants';

export default function AppV0(){
  return(
    <Router>
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route path="/pilot/faq" element={<PilotFAQ />} />
        <Route path="/pilot/about" element={<PilotAbout />} />

        <Route path="/home" element={<Homepage />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
  );
}