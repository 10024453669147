import "./common.css";

export default function Header() {
	return (
		<div className="header">
			<h1><a className="header-home" href="/home">Onthego</a></h1>
			<a className="header-signin" href="#">Sign In</a>
			<a className="header-signup" href="#">Sign Up</a>
		</div>
	);
}