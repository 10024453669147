import "./common.css";

function Footer(){
	return (
		<div className="footer-all">
			<div className="footer">
				<div className="footer-menu">
					<a href="/faq">FAQ</a>
					<a href="/about">About Us</a>
					<a href="https://forms.gle/Atw3WP52woM6Uf5A8" target="_blank">Restaurants</a>
					<a href="/">Pilot</a>
				</div>
				<div className="footer-social">
					<a href="#">Instagram</a>
					<a href="#">LinkedIn</a>
				</div>
			</div>
			<div className="footer-copyright">
				<p>© 2021 Onthego Delivery Inc. All rights reserved.</p>
			</div>
		</div>
	);
}

export default Footer;