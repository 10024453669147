import "./FAQ.css";

import Header from "../common/header";
import Body from "./body";
import Footer from '../common/footer';

export default function PilotFAQ() {
	return(
		<div className="faq">
			<Header />
			<Body />
			<Footer />
		</div>
	);
}