import "./homepage.css";

import Footer from '../common/footer';
import Main from './Main';

export default function Homepage() {
	return(
		<div className="homepage">
			<Main />
			<Footer />
		</div>
	);
}