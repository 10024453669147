export default function Main() {
	return (
		<div className="homepage-main">
			<div className="authspace">
				<div className="spacer"></div>
				<div className="auth">
					<a href="#" className="homepage-signin">Sign In</a>
					<a href="#" className="homepage-signup">Sign Up</a>
				</div>
			</div>
			<div className="homepage-hero">
				<h1>Onthego</h1>
				<h2>Delivery, Made Affordable</h2>
			</div>
		</div>
	);
}